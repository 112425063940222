<template>
  <div>
   <base-header class="pb-1">
       <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Assinatura PJ por arquivo</h6>
        </div>
      </div>
    </base-header>

   <div class="container-fluid mt--6">
		<form style="margin-top: 1rem">
			<card>
				<h3 slot="header" class="mb-0">Assinatura PJ por arquivo <a href="https://clubflex.s3.sa-east-1.amazonaws.com/Segmedic+Cadastro+PJ.xls" download><i title="Download de template para preenchimento" class="fa fa-download"></i></a></h3> 
				<div class="form-row">
					<div class="col-md-4">
						
					</div>
				</div>

				<div class="form-row">
					<div class="col-md-4">
						<label class="form-control-label">
							Arquivo com dados de assinatura e beneficiários PJ
						</label>
						<base-input
							type="file"

							placeholder="Arquivo Excel"
							@change="getFile($event)"
							name="arquivoExcel" ></base-input>
					</div>
				</div>

			</card>

			<div class="col-md-12 text-center mb-3">
              <base-button type="primary" @click="submit($event)">
                <i class="fas fa-file-export mr-2"></i>Importar
              </base-button>
			</div>
			
		</form>

   </div> <!--- fecha conteudo -->

  </div>
</template>
<script>
import { Select, Option } from 'element-ui'
import { Table, TableColumn, Tooltip } from 'element-ui'

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  mounted(){
    //this.loadPlan(); 
  },
  methods:{
	getFile: function (event) {
		this.file = event.target.files[0];
		console.log(this.file);
	},
	submit: function (event) {
		event.preventDefault();
		let formData = new FormData();
		formData.append("file", this.file);
		this.$clubApi.post('/support/loadFile/', formData)
		.then((response) => {
			console.log(response)
			location.href = "/assinatura/" + response.data.object.id;
		}) .catch((error) => {
			this.$notify({type: 'warning', message: error.response.data.msg})
		}).finally(() =>{
			NProgress.done() 
		})
    }
  }

};
</script>
<style></style>
